.form-checks {
    margin-bottom: 20px;
    
    .form-check {
        font-size: smaller;
        font-weight: normal;
        text-align: justify;
    }

    .ant-form-item {
        margin-bottom: 0px;
    }

    .ant-form-item-explain-error {
        font-size: smaller;
    }
}
@primary-color: #be0000;@menu-collapsed-width: 40px;@sidePageSpace_desktop: 15vw;@sidePageSpace_mobile: 5vw;