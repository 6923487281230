@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@bannerMainHeight: 30vw;
@bannerMainMaxHeight: 40vh;

.banner-main {
    width: 100%;
    height: min(@bannerMainHeight, @bannerMainMaxHeight);

    background-attachment: fixed;
    background-position-x: center;
    background-position-y: min(calc((50% - min(@bannerMainHeight, @bannerMainMaxHeight))/2), 0);
    background-size: 100% auto;
    background-repeat: no-repeat;

    &__logo-container {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &__logo {
        //animation: fadeIn 3s;
        position: absolute;
        margin: auto;
        width: 35vw;
        top: 0;
        left: -35vw;
        right: 0;
        bottom: 0;

        @media screen and (max-width: 768px) {
            left: -55vw;
        }
    }
}
@primary-color: #be0000;@menu-collapsed-width: 40px;@sidePageSpace_desktop: 15vw;@sidePageSpace_mobile: 5vw;