.ant-layout-header {
    padding-left: calc(@sidePageSpace_desktop - 20px) !important;
    padding-right: calc(@sidePageSpace_desktop - 20px) !important;

    z-index: 999 !important;
    overflow: hidden;

    span {
        font-size: 12px !important;
        font-weight: bold !important;

        // @media screen and (max-width: 1710px) { 
        //     font-size: 10px !important
        // }
    }

    .ant-menu-sub {
        background-color: black !important;
    }

    @media screen and (max-width: 768px) {
        padding-left: calc(@sidePageSpace_mobile - 20px) !important;
        padding-right: calc(@sidePageSpace_mobile - 20px) !important;
    }

    .ant-menu-submenu {
        background-color: black !important;
    
        ul {
            background-color: black !important;
    
            li, li>span {
                color: white;
                font-weight: bold !important;

                :hover {
                    color: @primary-color !important;
                }
            }
        }
    }
}

.ant-menu-submenu-popup {
    color: white;
    font-weight: bold !important;
    background-color: black !important;

    ul {
        background-color: black !important;
        
        li {
            color: white;
            font-weight: bold !important;

            .ant-menu-title-content {
                a {
                    color: white;
                    font-weight: bold !important;
                }

                :hover {
                    color: @primary-color !important;
                }
            }

            .ant-menu-submenu-arrow {
                color: white;
                font-weight: bold !important;
            }
        }
    }
}
@primary-color: #be0000;@menu-collapsed-width: 40px;@sidePageSpace_desktop: 15vw;@sidePageSpace_mobile: 5vw;