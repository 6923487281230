@import 'antd/dist/antd.css';
.latest-loading {
  width: 100vw;
  align-items: center;
}
.latest-events {
  width: calc(100vw - 15vw * 2);
  margin-left: 15vw !important;
  margin-right: 15vw !important;
  margin-bottom: 50px !important;
  position: relative;
}
@media screen and (max-width: 768px) {
  .latest-events {
    width: calc(100vw - 5vw * 2);
    margin-left: 5vw !important;
    margin-right: 5vw !important;
  }
}
.latest-events__arrow {
  position: absolute;
  z-index: 3;
  top: 0;
  padding-top: calc(0.45 * 80px);
  width: 20%;
  height: 140px;
}
.latest-events__arrow svg {
  height: 80px;
  width: 80px;
  color: white;
}
.latest-events__arrow__prev {
  padding-left: 30px;
  left: 0;
}
.latest-events__arrow__next {
  padding-right: 30px;
  right: 0px;
}
.latest-events .slick-dots-bottom {
  bottom: -15px !important;
}
.latest-events .slick-dots-bottom button {
  background-color: #be0000 !important;
}
.latest-events .grayed {
  transform: scale(0.9);
  transition: transform 500ms;
  opacity: 0.5;
  padding-top: 13px;
  padding-bottom: 13px;
}
.latest-events .highlighted {
  transform: scale(1.01);
  transition: transform 500ms;
  opacity: 1;
  padding-top: 13px;
  padding-bottom: 13px;
}
