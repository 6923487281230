.admin-page {
	&__sidebar {
		.ant-layout-sider-trigger {
			background-color: @primary-color;

			> span > svg {
				color: white;
			}
		}
	}
}

@primary-color: #be0000;@menu-collapsed-width: 40px;@sidePageSpace_desktop: 15vw;@sidePageSpace_mobile: 5vw;