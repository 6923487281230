.footer-main {
    background-color: black;
    color: gray;
    padding-left: @sidePageSpace_desktop;
    padding-right: @sidePageSpace_desktop;
    padding-top: 20px;
    padding-bottom: 30px;
    width: 100vw;
    font-size: 12px;

    //vertical-align: middle;

    @media screen and (max-width: 768px) {
        padding-left: @sidePageSpace_mobile;
        padding-right: @sidePageSpace_mobile;
    }

    a {
        color: gray;
    }

    &__left-divider {
        //padding-top: 30px;
        padding-left: 20px;
        border-left: 1px solid gray;

        @media screen and (max-width: 768px) {
            border-left: none;
        }
    }

    &__sm-icon {
        margin-top: 5px !important;
        font-size: xx-large;
    }
}

.ant-layout-footer {
    background-color: black !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}
@primary-color: #be0000;@menu-collapsed-width: 40px;@sidePageSpace_desktop: 15vw;@sidePageSpace_mobile: 5vw;