.contact-form {
    background-color: @primary-color;
    width: 100%;
    padding-top: 50px;
    padding-left: calc(@sidePageSpace_desktop * 2);
    padding-right: calc(@sidePageSpace_desktop * 2);
    padding-bottom: 50px;
    color: white;
    margin-bottom: -24px;
    box-shadow: 0px -2px 5px 5px rgba(0, 0, 0, 0.4);

    @media screen and (max-width: 768px) {
        padding-left: @sidePageSpace_mobile;
        padding-right: @sidePageSpace_mobile;
    }

    &__title {
        font-size: xx-large;
    }

    &__form {
        &__button {
            height: 40px !important;
            width: 150px !important;
            font-weight: bolder !important;
        }
    }

    .ant-form-item-explain-error {
        color: lightgray !important;
    }
}
@primary-color: #be0000;@menu-collapsed-width: 40px;@sidePageSpace_desktop: 15vw;@sidePageSpace_mobile: 5vw;