@import url('https://fonts.googleapis.com/css?family=Montserrat');
body {
  font-family: 'Montserrat' !important;
  overflow-x: hidden;
}
.bg-site {
  background-color: black !important;
}
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh !important;
}
.content-layout {
  background: #fff;
  flex: 1 0 auto;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
.ant-layout-sider-trigger {
  position: inherit !important;
}
.w-fit {
  width: fit-content !important;
}
body::-webkit-scrollbar {
  width: 0.5em;
}
body::-webkit-scrollbar-track {
  border: 0.1px solid lightgray;
  border-radius: 0.25em;
}
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 0.25em;
}
.fixed-header {
  position: fixed;
  z-index: 10;
  width: 100%;
}
.ant-form-item-control-input .ant-input,
.ant-form-item-control-input .ant-picker,
.ant-form-item-control-input .ant-input-number,
.ant-form-item-control-input .ant-upload,
.ant-form-item-control-input .ant-input-affix-wrapper {
  border-radius: 7px;
}
.ant-form-item-control-input .ant-select-selector {
  border-radius: 7px !important;
}
.ant-form-item-control-input .ant-input-group-addon {
  border-radius: 0px 7px 7px 0px;
}
.ant-btn {
  border-radius: 7px !important;
}
.app-button-big {
  background-color: white !important;
  color: #be0000 !important;
  font-weight: 800 !important;
  font-size: x-large !important;
  width: 300px !important;
  height: 60px !important;
  border-radius: 7px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}
.grecaptcha-badge {
  visibility: hidden;
}
.ant-cascader-menu {
  min-height: 300px !important;
}
