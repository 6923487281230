.ant-picker,
.ant-input-number {
    width: 100% !important;
}

.map-container {
    height: 300px;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 7px !important;
    overflow: hidden;
    
    .leaflet-container {
        height: 100%;
    }
}

.hidden {
    display: none !important;
}

.update-event-form {
    label {
        font-weight: bold;
    }

    h3 {
        margin-top: 50px;
    }
}
@primary-color: #be0000;@menu-collapsed-width: 40px;@sidePageSpace_desktop: 15vw;@sidePageSpace_mobile: 5vw;