@blank: '../../../../../App/common/assets/blank.webp';

@bannerAspectRatio: 0.45;
@bannerHeight: 22vw;

.event-container {
    padding-top: 40px;

    &__banner {
        width: calc(100vw - @sidePageSpace_desktop * 2);
        height: @bannerHeight;

        margin-left: @sidePageSpace_desktop;
        //margin-left: calc((100vw - @bannerWidth)/2);
        //margin-right: calc((100vw - @bannerWidth)/2);
        margin-bottom: 50px;

        background-position: left center;
        background-size: contain;
        background-repeat: no-repeat;

        @media screen and (max-width: 768px) {
            margin-left: @sidePageSpace_mobile;
            width: calc(100vw - @sidePageSpace_mobile * 2);
            height: calc((100vw - @sidePageSpace_mobile * 2) * @bannerAspectRatio);

            background-position: center;
            background-size: contain;
        }
    }
    
    table {
        margin-bottom: 50px;
        width: 100%;
        table-layout: fixed;

        @media screen and (max-width: 768px) {
            font-size: smaller;
        }

        tr {
            border-bottom: 1px solid lightgray;
        }
    }

    &__content {
        width: 100%;
        padding-left: @sidePageSpace_desktop !important;
        padding-right: @sidePageSpace_desktop;

        @media screen and (max-width: 768px) {
            padding-left: @sidePageSpace_mobile !important;
            padding-right: @sidePageSpace_mobile;
        }
    }

    &__label {
        color: @primary-color;
        font-weight: bold;
        padding-bottom: 5px;
        width: 50%;

        @media screen and (max-width: 768px) {
            width: 40%;
        }
    }

    &__value {
        width: 50%;
        word-wrap: break-word;

        @media screen and (max-width: 768px) {
            width: 60%;
        }
    }

    .map-container {
        z-index: 1;
        position: relative;
        height: 350px;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 30px;
        border-radius: 7px !important;
        overflow: hidden;
        
        .leaflet-container {
            height: 100%;
        }
    }

    &__buttons {
        margin-bottom: 30px;

        .ant-btn {
            width: 300px;

            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }
    }
}
@primary-color: #be0000;@menu-collapsed-width: 40px;@sidePageSpace_desktop: 15vw;@sidePageSpace_mobile: 5vw;