@cardHeight: 200px;
@imageWidth: 100%;

.event-card-mobile {
    border: 1px solid black;
    border-radius: 10px 0px 0px 10px;
    margin-top: 20px !important;
    margin: 10px 0px;
    overflow: hidden;
    height: @cardHeight;
    width: 100%;
    box-shadow: 0px 0px 7px 4px rgba(0, 0, 0, 0.4);
    position: relative;

    &__live {
        position: absolute;
        bottom: 0px;
        right: 0px;
        z-index: 10;
        padding: 0px 10px;
        width: 100%;

        font-weight: bolder;
        text-align: right;
        vertical-align: bottom;

        color: @primary-color;
    }

    &__content {
        padding-top: 5px !important;
        padding: 10px;
        width: 100%;
    }

    &__section {
        width: 100% !important;
    }

    &__image {
        height: calc(@cardHeight * 1/2);
        border-radius: 10px 0px 0px 10px;
        overflow: hidden;
        width: @imageWidth;
        box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.3);

        &__bg {
            height: 100%;
            width: 100%;
            margin: 0px !important;

            background-color: gray;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        img {
            max-width: 100%;
        }
    }

    &__header {
        font-weight: bold;
        width: 100%;
        color: black;
        font-size: small;

        &__title {
            float: left;
            clear: both;
            height: 2em;
            width: 67%;
            text-overflow: ellipsis;
            white-space: nowrap; 
            overflow: hidden;

            margin-left: -5px;
            padding-top: 2px;
            padding-left: 5px;
            padding-right: 5px;
            background-color: black;// @primary-color;
            color: white;
            border-radius: 7px;
        }

        &__date {
            float: right;
            text-align: right;
            width: 33%;
            font-size: smaller;
        }
    }

    &__location {
        color: @primary-color;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap; 
        overflow: hidden;
        font-size: x-small;
    }

    &__description {
        color: black;
        font-size: x-small;

        &__tag {
            //height: 2em !important;
            line-height: normal !important;
            padding: 2px !important;
            font-size: xx-small !important;
            border-radius: 7px !important;
        }
    }
}
@primary-color: #be0000;@menu-collapsed-width: 40px;@sidePageSpace_desktop: 15vw;@sidePageSpace_mobile: 5vw;