.cookies-eu-banner {
    background: #444;
    color: #fff;
    padding: 6px @sidePageSpace_desktop;
    font-size: 13px;
    text-align: justify;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 999;

    @media screen and (max-width: 768px) {
        padding: 6px @sidePageSpace_mobile;
    }

    button {
        text-decoration: none;
        background-color: #222;
        color: #fff;
        border: 1px solid #000;
        cursor: pointer;
        padding: 4px 7px;
        margin: 2px 0;
        font-size: 13px;
        font-weight: 700;
        transition: backgroundColor 0.07s, color 0.07s, border-color 0.07s;

        :hover {
            background: #fff;
            color: #222;
        }
    }
}
@primary-color: #be0000;@menu-collapsed-width: 40px;@sidePageSpace_desktop: 15vw;@sidePageSpace_mobile: 5vw;