@cardHeight: 140px;
@imageWidth: 33%;

.event-card {
    border: 1px solid black;
    border-radius: 10px 0px 0px 10px;
    margin-top: 10px !important;
    margin: 10px 0px;
    overflow: hidden;
    height: @cardHeight;
    width: 100%;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
    position: relative;

    &:hover {
        background-color: rgb(233, 233, 233);
    }

    &__live {
        position: absolute;
        bottom: 0px;
        right: 0px;
        z-index: 10;
        padding: 0px 10px;
        width: 100px;

        font-size: large;
        font-weight: bolder;
        text-align: right;
        vertical-align: bottom;

        color: @primary-color;
        background-color: transparent !important;
    }

    &__content {
        padding: 10px;
        padding-left: 20px;
        width: calc(100% - @imageWidth);
    }

    &__section {
        margin-top: 0px !important;
        width: 100% !important;
    }

    &__image {
        max-height: @cardHeight;
        border-radius: 10px;
        overflow: hidden;
        width: @imageWidth;
        box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.3);

        &__bg {
            height: 100%;
            width: 100%;
            margin: 0px !important;

            background-color: gray;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        img {
            max-width: 100%;
        }
    }

    &__header {
        font-weight: bold;
        width: 100%;
        color: black;
        font-size: medium;

        &__title {
            float: left;
            clear: both;
            width: 65%;
            text-overflow: ellipsis;
            white-space: nowrap; 
            overflow: hidden;
        }

        &__date {
            float: right;
            text-align: right;
            width: 30%;
        }
    }

    &__location {
        color: @primary-color;
        max-width: 65%;
        text-overflow: ellipsis;
        white-space: nowrap; 
        overflow: hidden;
        font-size: small;
    }

    &__description {
        padding-top: 5px;
        color: black;
        font-size: small;
    }
}
@primary-color: #be0000;@menu-collapsed-width: 40px;@sidePageSpace_desktop: 15vw;@sidePageSpace_mobile: 5vw;