.search-form {
    width: 100%;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    background-color: rgb(192, 0, 0);
    margin-bottom: 50px !important;
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.3);

    overflow: hidden;
        
    .page-label {
        margin-bottom: 30px !important;
    }

    &__form {
        padding-left: @sidePageSpace_desktop !important;
        padding-right: @sidePageSpace_desktop !important;

        @media screen and (max-width: 768px) {
            padding-left: @sidePageSpace_mobile !important;
            padding-right: @sidePageSpace_mobile !important;
        }
    
        .ant-form-item {
            margin-bottom: 10px !important;
        }
    
        .ant-form-item-label label {
            text-overflow: ellipsis;
            white-space: nowrap; 
            overflow: hidden;
            width: 100% !important;
            font-size: 12px;
            color: white !important;
        }
    
        .ant-checkbox-wrapper {
            color: white !important;
            margin-top: 0px !important;
            padding-top: 0px !important;
    
            span {
                display: inline-block;
                vertical-align: middle;
            }
        }
    
        .ant-checkbox-input, .ant-checkbox-inner {
            height: 25px !important;
            width: 25px !important;
            border-radius: 7px;
        }
    
        .slider-col {
            padding-left: 30px;
            padding-right: 30px;
        }
    
        .ant-slider:hover {
            .ant-slider-rail {
                background-color: #534a49 !important;
            }
        }
    
        .ant-slider-rail {
            background-color: #332d2c;
        }
    
        .search-button {
            margin-top: 20px !important;
            background-color: white !important;
            color: black !important;
            font-weight: bold;
            width: 200px !important;
        }
    
        .show-button {
            cursor: pointer;
            color: white;
        }
    }    
}
@primary-color: #be0000;@menu-collapsed-width: 40px;@sidePageSpace_desktop: 15vw;@sidePageSpace_mobile: 5vw;