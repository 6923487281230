@stripheight: 40px;
@marginspacing: 50px;
@marginspacing-mobile: 20px;

.page-label {
    margin-bottom: @marginspacing;

    left: 0;
    right: 0;
    width: 100vw;
    display: flex;

    @media screen and (max-width: 768px) {
        margin-bottom: @marginspacing-mobile;
    }

    &__content {
        overflow: auto;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5);
        border-radius: 0px 7px 7px 0px;
        background-color: white;
    }

    &__content-right {
        overflow: visible;
        background-color: white;
    }

    &__message {
        line-height: @stripheight;
        float: left;
        color: @primary-color !important;
        text-align: left;
        font-size: large;
        font-weight: bold;
        margin-right: 30px;
        margin-left: @sidePageSpace_desktop;
        max-width: calc((100vw - (2 * @sidePageSpace_desktop)) * 0.65);

        @media screen and (max-width: 992px) {
            font-size: small;
        }

        @media screen and (max-width: 768px) {
            font-size: medium;
            margin-left: @sidePageSpace_mobile;
            max-width: calc(100vw - (2 * @sidePageSpace_mobile + 30px));
        }
    }

    &__message-right {
        border-radius: 7px;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5);
        line-height: @stripheight;
        margin-right: @sidePageSpace_desktop;
        padding-left: 30px;
        padding-right: 30px;
        float: right;
        color: @primary-color !important;
        text-align: left;
        font-size: large;
        font-weight: bold;

        @media screen and (max-width: 992px) {
            font-size: small;
        }

        @media screen and (max-width: 768px) {
            margin-right: @sidePageSpace_mobile;
            font-size: medium;
        }
    }

    &__clear {
        flex: 1 1 auto;
        min-width: 30px;
    }
}
@primary-color: #be0000;@menu-collapsed-width: 40px;@sidePageSpace_desktop: 15vw;@sidePageSpace_mobile: 5vw;