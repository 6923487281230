.all-logs-card {
	border: 1px solid black !important;
	margin-top: 1em;

	&:hover {
		cursor: pointer;
	}

	&__download-icon {
		font-size: 32px;
	}
}

@primary-color: #be0000;@menu-collapsed-width: 40px;@sidePageSpace_desktop: 15vw;@sidePageSpace_mobile: 5vw;