.all-logs-container {
	&__logs-content-row {
		@media (max-width: 768px) {
			margin: 5px;
		}

		&__logs-content {
			overflow-x: scroll;
			overflow-y: scroll;
			height: 80vh !important;

			@media (max-width: 768px) {
				height: 45vh !important;
			}
		}

		&__col-cards {
			overflow-y: scroll;
			overflow-x: hidden;
			height: 80vh;

			@media (max-width: 768px) {
				.col-cards {
					margin-top: 20px;
					height: 25vh;

					.ant-card-body {
						padding: 3px;
					}
				}
			}
		}
	}
}

@primary-color: #be0000;@menu-collapsed-width: 40px;@sidePageSpace_desktop: 15vw;@sidePageSpace_mobile: 5vw;