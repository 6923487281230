.terms-of-service-container {
    padding-top: 50px;
    padding-bottom: 100px;
    
    padding-left: @sidePageSpace_desktop;
    padding-right: @sidePageSpace_desktop;

    text-align: justify;

    @media screen and (max-width: 768px) {
        padding-left: @sidePageSpace_mobile;
        padding-right: @sidePageSpace_mobile;
    }
}
@primary-color: #be0000;@menu-collapsed-width: 40px;@sidePageSpace_desktop: 15vw;@sidePageSpace_mobile: 5vw;