@adSpacing: 2vw;

.parts-column {
    border-left: 1px solid gray;
    margin-top: 10px;
    margin-bottom: @adSpacing;
    margin-left: @adSpacing;
    padding-left: @adSpacing;
    //padding-right: @adSpacing;

    .parts-tile {
        box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.4);
        margin-bottom: @adSpacing;

        :hover {
            scale: 1.02;
        }
    }

    @media screen and (max-width: 768px) {
        border-left: none;
        width: 100%;
        padding-left: @sidePageSpace_mobile;
        padding-right: @sidePageSpace_mobile;
        margin-left: 0;
    }
}
@primary-color: #be0000;@menu-collapsed-width: 40px;@sidePageSpace_desktop: 15vw;@sidePageSpace_mobile: 5vw;