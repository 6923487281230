.landing-page-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	.ant-row {
		margin-top: 0px;
	}
}

.home {
	&__navbar {
		width: 100%;
		background-color: black !important;
		position: sticky;
		top: -1px;
		z-index: 999;
	}
}

.home-container {
	&__event-list-container {
		padding-left: @sidePageSpace_desktop;
		padding-right: @sidePageSpace_desktop;
		margin-bottom: 20px;
		width: 100%;

		.ant-tabs-tab-active {
			border-radius: 7px 7px 0px 0px;
			background-color: @primary-color;
			padding: 0px 15px;

			.ant-tabs-tab-btn {
				color: white !important;
			}
		}

		.view-switch {
			color: @primary-color;
			font-size: xx-large;
			margin-bottom: 10px;
		}

		@media screen and (max-width: 768px) {
			padding-left: @sidePageSpace_mobile;
			padding-right: @sidePageSpace_mobile;
		}
	}
}
@primary-color: #be0000;@menu-collapsed-width: 40px;@sidePageSpace_desktop: 15vw;@sidePageSpace_mobile: 5vw;