.event-calendar {
    margin-bottom: 50px;

    .fc-toolbar-title {
        font-weight: bolder;
        text-transform: uppercase;
    }

    .fc-daygrid-dot-event,
    .fc-daygrid-block-event { 
        padding: 0px !important;
        font-size: 9px;

        .event-tile {
            max-width: 100%;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;
            overflow: hidden;
        }
    }
}
@primary-color: #be0000;@menu-collapsed-width: 40px;@sidePageSpace_desktop: 15vw;@sidePageSpace_mobile: 5vw;