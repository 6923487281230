@cardPadding: 30px;

.member-card {
    padding-left: @cardPadding;
    padding-right: @cardPadding;

    @media screen and (max-width: 768px) {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 20px;
    }

    &__ppf {
        justify-content: center;
        display: flex;
        margin-bottom: 30px;

        span {
            margin: 0 auto;
            box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.4);
        }
    }

    &__name {
        font-size: larger;
    }

    &__description {
        text-align: justify;
    }

    &__social-media {
        margin-top: 5px;
        margin-bottom: 5px;

        a {
            color: black !important;
            transition: color 200ms;

            :hover {
                transition: color 200ms;
                color: @primary-color;
            }
        }

        &__icon {
            font-size: large;
        }
    }
}
@primary-color: #be0000;@menu-collapsed-width: 40px;@sidePageSpace_desktop: 15vw;@sidePageSpace_mobile: 5vw;