.event-list {
    margin-bottom: 50px;
    width: 100%;

    &__pagination-row {
        align-items: center;
        display: flex;
        margin-top: 20px;

        &__pagination {
            margin: 0 auto !important;

            .ant-pagination-item-active {
                background-color: @primary-color;

                a {
                    color: white !important;
                }
            }
        }
    }
}
@primary-color: #be0000;@menu-collapsed-width: 40px;@sidePageSpace_desktop: 15vw;@sidePageSpace_mobile: 5vw;