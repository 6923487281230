.event-carousel-card {
    width: 94%;
    margin-left: 3%;
    margin-right: 3%;
    //max-height: 90%;
    background-color: lightgray;
    border: 2px solid white;
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.3);
    
    &__image {
        height: 100px;
        width: 100%;

        background-size: cover;
        background-position: center;
    }

    &__text {
        padding: 5px;
    }

    &__header {
        color: black;
        display: flex;
        width: 100%;

        &__title {
            max-width: 90%;
            text-overflow: ellipsis;
            white-space: nowrap; 
            overflow: hidden;
        }
    }

    &__content {
        color: black;
        min-height: 1em;

        .organizer {
            text-overflow: ellipsis;
            white-space: nowrap; 
            overflow: hidden;
        }
    }
}
@primary-color: #be0000;@menu-collapsed-width: 40px;@sidePageSpace_desktop: 15vw;@sidePageSpace_mobile: 5vw;