.justify-content-start {
    background-color: black !important;
    color: white !important;
    border-bottom: none !important;

    .ant-menu-submenu,
    li {
        background-color: black !important;
    }

    a {
        color: white !important;
    }
}

.justify-content-end {
    background-color: black !important;
    color: white !important;
    border-bottom: none !important;

    .ant-menu-submenu,
    li {
        background-color: black !important;
    }
}

.category-option {
    background-color: black;
    color: white;
    margin: 0 !important;
}

.ant-divider {
    border-left: 1px solid white !important;
}
@primary-color: #be0000;@menu-collapsed-width: 40px;@sidePageSpace_desktop: 15vw;@sidePageSpace_mobile: 5vw;